import FournisseursProduitsLayout from '../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout';
import './style.scss';
import { DOMAIN } from '../../../config/env';

const Solutions = () => {
  return (
    <FournisseursProduitsLayout>
      <div className="solution">
        <h3 className="solution-title">Solutions Logistiques</h3>
        <div className="solution-content">
          <div className="solution-map">
            <a
              href={`${DOMAIN.URL}/_fichier/_solutions/2023/fournisseurs%20avec%20PEA%202023.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Guide des solutions PEA
            </a>{' '}
            Franco 800€
            <img
              className="solution-image"
              src={`${DOMAIN.URL}/_fichier/_solutions/2023/pea.jpg`}
              alt=""
            />
          </div>
          <div className="solution-map">
            <a
              href={`${DOMAIN.URL}/_fichier/_solutions/2023/fournisseurs%20avec%20Leduc%20lubot%202022%202023.pdf`}
            >
              Guide des solutions LEDUC LUBOT
            </a>{' '}
            Franco 500€
            <img
              className="solution-image"
              src={`${DOMAIN.URL}/_fichier/_solutions/2022/leduc.jpg`}
              alt=""
            />
          </div>
        </div>
      </div>
    </FournisseursProduitsLayout>
  );
};

export default Solutions;
