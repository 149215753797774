import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {getBanniere} from "../../../../services/apis";
import { RESSOURCE } from '../../../../config/constants';
import { DOMAIN } from '../../../../config/env';
import useAuth from '../../../../hooks/useAuth';
import './style.scss';

const AcceuilBannerCarousel = () => {
  const { magasin } = useAuth(); // magasins code
  const [bannieres, setBanniere] = useState([]);

  const location = useLocation();
  const cheminActuel = location.pathname;

  const correspondanceChemin = {
      '/': 1,
      '/fp/aide-achat-vte': 6,
  };
  const valeurParDefaut = 999;
  const numeroPage = correspondanceChemin[cheminActuel] || valeurParDefaut;
  
  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getBanniere(magasin.mag_code, numeroPage);
      setBanniere(res);
    };

    fetchData();
  }, [magasin.mag_code]);

  //React.useEffect(() => console.log(cheminActuel), [magasin]);

  return (
    <div className="accueil-carousel-wrap">
      <swiper-container
        space-between="15"
        autoplay="true"
        className="swiper-accueil"
        slides-per-view="1"
        speed="500"
      >
        {bannieres.map((banniere, i) => (
          <swiper-slide
            key={'banner-slide-' + i}
            className="swiper-banner-slide"
          >
            <img className="banner" src={
                      DOMAIN.URL +
                      '/' +
                      RESSOURCE.BANNIERE +
                      '/' +
                      banniere.image
                    } alt="" />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
};

export default AcceuilBannerCarousel;
