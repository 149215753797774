import React from "react";
import "./style.scss";
import logoInedis from "./logo-inedis.png";
import logoReferencia from "./logo-referencia.png";
import logoIsCom from "./logo-iscom.png";

const Footer = () => {
  return (
    <footer className="footer">
      <ul>
        <li>
          <div className="item">
            <div className="img-wrap">
              <img src={logoInedis} alt="logo inedis" />
            </div>
            <p>
              <span className="title"> Développement </span>
              <br /> 02 28 02 29 38 <br />
              contact@groupe-inedis.fr
            </p>
          </div>
        </li>
        <li>
          <div className="item">
            <div className="img-wrap">
              <img src={logoReferencia} alt="logo referencia" />
            </div>
            <p>
              <span className="title"> Produits </span>
              <br /> 02 28 02 29 29 <br />
              contact@inedis-referencia.fr
            </p>
          </div>
        </li>
        <li>
          <div className="item">
            <div className="img-wrap">
              <img src={logoIsCom} alt="logo iscom" />
            </div>
            <p>
              <span className="title">Communication</span>
              <br /> 02 28 02 29 34 <br />
              communication@inedis-services.fr
            </p>
          </div>
        </li>
      </ul>
    </footer>
  );
};
export default Footer;
