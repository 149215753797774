import React, { useState, useEffect } from "react";
import { getCommandeByMagasin } from "../../../../services/boutique_api";
import useAuth from "../../../../hooks/useAuth";
import TrashIcon from "../../../../components/ui/icons/TrashIcon";
import "./style.scss";

function ModalCommande({ show, onClose, commande }) {
  if (!show || !commande) return null;
  console.log(commande);
  return (
    <div className="modal-commande">
      <div className="modal-content">
        <h2>Ma commande</h2>
        <hr />

        <div className="form-group">
          {commande.Date && (
            <p>
              <strong>Date :</strong> {commande.Date}
            </p>
          )}
          {commande.Magasin && (
            <p className="magasin-info">
              <strong>Magasin :</strong> {commande.Magasin}
            </p>
          )}
          {commande.Contact && (
            <p className="contact-info">
              <strong>Contact :</strong> {commande.Contact}
            </p>
          )}
          {commande.Mail && (
            <p className="mail-info">
              <strong>Mail :</strong> {commande.Mail}
            </p>
          )}
          {commande.Telephone && (
            <p className="phone-info">
              <strong>Telephone :</strong> {commande.Telephone}
            </p>
          )}
          {commande.AdresseDeLivraison && (
            <p className="address-info">
              <strong>Adresse de livraison :</strong>{" "}
              {commande.AdresseDeLivraison}
            </p>
          )}
          {commande.Commentaire && (
            <p className="comment-info">
              <strong>Commentaire :</strong> {commande.Commentaire}
            </p>
          )}
          {commande.Etat && (
            <p className="etat-info">
              <strong>Etat de votre commande :</strong> {commande.Etat}
            </p>
          )}
        </div>

        <table>
          <thead>
            <tr>
              <th>Référence</th>
              <th>Désignation</th>
              <th>Conditionnement</th>
              <th>Prix Vente HT</th>
              <th>Quantité</th>
              <th>Taille</th>
              <th>Couleur</th>
              <th>Logo</th>
              <th>Montant HT</th>
            </tr>
          </thead>
          <tbody>
            {/* Vous devrez ajuster la map en fonction de la structure de vos données */}
            {commande.DetailDeLaCommande.map((detail, index) => (
              <tr key={index}>
                <td>{detail.reference}</td>
                <td>{detail.designation}</td>
                <td>{detail.conditionnement}</td>
                <td>{detail.prixVenteHT} €</td>
                <td>{detail.quantite}</td>
                <td>{detail.taille}</td>
                <td>{detail.couleur}</td>
                <td>{detail.logo}</td>
                <td>{detail.montantHT} €</td>
              </tr>
            ))}
            <tr className="total-row">
              <td colSpan="8" style={{ textAlign: "center" }}>
                Montant commande HT hors frais d'expédition :
              </td>
              <td style={{ textAlign: "center" }}>
                {commande.MontantCommandeHT} €
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div className="form-actions">
          <button className="close-modal-btn" onClick={onClose}>
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
}

const Commande = () => {
  const [commande, setCommande] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCommande, setSelectedCommande] = useState(null);
  const { magasin } = useAuth();

  useEffect(() => {
    async function fetchRayons() {
      let data;

      data = await getCommandeByMagasin(magasin.mag_code);

      setCommande(data);
    }

    fetchRayons();
  }, [commande]);

  const handleOpenModal = (commandeItem) => {
    setSelectedCommande(commandeItem);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedCommande(null);
    setShowModal(false);
  };

  return (
    <div>
      <div className="titre-panier">Mes commandes</div>

      <table>
        <thead>
          <tr>
            <th className="tab-commande">Date</th>
            <th className="tab-commande">Magasin</th>
            <th className="tab-commande">Contact</th>
            <th className="tab-commande">Mail</th>
            <th className="tab-commande">Montant</th>
            <th className="tab-commande">Etat</th>
            <th className="tab-commande">Détail</th>
          </tr>
        </thead>
        <tbody>
          {commande.map((item, index) => (
            <tr key={index}>
              <td>{item.Date}</td>
              <td>{item.Magasin}</td>
              <td>{item.Contact}</td>
              <td>{item.Mail}</td>
              <td>{item.MontantCommandeHT} €</td>
              <td>{item.Etat}</td>
              <td>
                <button
                  className="detailButton"
                  onClick={() => handleOpenModal(item)}
                >
                  Détail
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalCommande
        show={showModal}
        onClose={handleCloseModal}
        commande={selectedCommande}
      />
    </div>
  );
};
export default Commande;
